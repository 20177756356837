<template>
  <div id="azure-login" class="dx-viewport">
    <img class="logo" src="/Web/assets/img/logo-terraindex.svg" width="150" height="107" />
    <!-- User is not connected, ask user to log in to Classic. -->
    <div v-show="loginState === 'notoken'">
      <div class="login-selection-panel">
        <div v-if="inviteError" class="invite-invalid">{{ this.inviteError }}</div>
        <B2CLoadingSpinner />
      </div>
    </div>
    <div v-show="loginState === 'classiclogin'">
      <div class="login-title">{{ $t('message.connectAzureAuthentication.title') }}</div>
      <div class="login-selection-panel">
        <div class="login-instruction">
          {{ $t('message.connectAzureAuthentication.instruction') }}
        </div>
        <div v-if="loginError" class="login-invalid">{{ $t('message.connectAzureAuthentication.invalid-credentials') }}</div>
        <div class="login-options">
          <label for="username">
            {{ $t('message.connectAzureAuthentication.username') }}
          </label>
          <input v-model="username" type="text"  class="login-select" />
          <label for="password">
            {{ $t('message.connectAzureAuthentication.password') }}
          </label>
          <input v-model="password" type="password"  class="login-select"  />
          <button class="login-button button" type="button" @click="onClassicLogin" >
            {{ $t('message.connectAzureAuthentication.login') }}
          </button>
          <a :href="forgotClassicPasswordUrl"> {{ $t('message.connectAzureAuthentication.forgot-classic-password') }} </a>
        </div>
      </div>
    </div>
    <div v-show="loginState === 'displayazuredocumentation'">
      <div class="login-title">{{ $t('message.connectAzureAuthentication.documentation.title') }}</div>
      <div class="login-documentation-panel">
        <div class="login-instruction">
          <p>{{ $t('message.connectAzureAuthentication.documentation.content.web.content') }}</p>
          <p>{{ $t('message.connectAzureAuthentication.documentation.content.web.readMore') }}</p>
        </div>
        <br/>

        <h3>{{ $t('message.connectAzureAuthentication.documentation.content.app.title') }}</h3>
        <p>{{ $t('message.connectAzureAuthentication.documentation.content.app.content') }}</p>

        <h3>{{ $t('message.connectAzureAuthentication.documentation.content.appNotYetSynced.title') }}</h3>
        <p>{{ $t('message.connectAzureAuthentication.documentation.content.appNotYetSynced.content') }}</p>

        <h3>{{ $t('message.connectAzureAuthentication.documentation.content.geoServer.title') }}</h3>
        <p>{{ $t('message.connectAzureAuthentication.documentation.content.geoServer.content') }}</p>
        <button class="generate-button button" type="button" @click="onGenerateButtonClick" >
          {{ $t('message.connectAzureAuthentication.documentation.generate') }}
        </button>
        <button class="continue-button button" type="button" @click="onContinueButtonClick" >
          {{ $t('message.connectAzureAuthentication.documentation.continue') }}
        </button>
      </div>
    </div>
    <div v-show="loginState === 'nolicense'">
      <div v-if="loadingLicenses">
        <div class="login-selection-panel">
          <B2CLoadingSpinner />
        </div>
      </div>
      <div v-else>
        <div class="login-selection-panel">
          <div class="login-instruction">
            {{ $t('message.connectAzureAuthentication.no-license') }}
          </div>
        </div>
      </div>
    </div>
    <div v-show="loginState === 'cufailed'">
      <div class="login-selection-panel">
        <div class="login-instruction">{{ $t('message.connectAzureAuthentication.cu.failed') }}</div>
        <div class="login-options">
          <label style="text-align: center; padding: 0; margin-bottom: 1em">
            {{ $t('message.connectAzureAuthentication.cu.usage') }}
          </label>
        </div>
        <table>
          <thead>
            <tr>
              <th v-for="header in tableHeaders" :key="header">{{ header }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in cuUsers" :key="user.userName">
              <td>{{ user.userName }}</td>
              <td>{{ `${user.minutesInactive} min` }}</td>
            </tr>
          </tbody>
        </table>
        <input type="submit" class="login-button button" :value="$t('message.connectAzureAuthentication.return')" @click="returnToLogin" />
      </div>
    </div>
    <div v-show="loginState === 'selectlicense'">
      <div class="login-title">
        {{ $t('message.connectAzureAuthentication.almostThere') }}
      </div>
      <div class="login-selection-panel">
        <div class="login-instruction">
          {{ $t('message.connectAzureAuthentication.explanationLicense') }}
        </div>
        <div class="login-options">
          <label for="selectedLicense">
            {{ $t('message.connectAzureAuthentication.selectLicense') }}
          </label>
          <DxSelectBox v-model="selectedLicense" class="login-select"
            :search-enabled="true"
            :data-source="licenses"
            :search-timeout="100"
            display-expr="displayValue"
            @focus-in="onFocusSelectedLicense"
          />
          <input
            type="submit"
            class="login-button button"
            :disabled="!selectedLicense"
            :value="$t('message.connectAzureAuthentication.login')"
            @click="onSelectLicense"
          />
        </div>
      </div>
    </div>
    <div v-show="loginState === 'tokenfailed'">
      <div class="login-selection-panel">
        <div class="login-instruction">
          <p class="error-icon"></p>
          <p>{{$t('message.connectAzureAuthentication.login-token-failure')}}</p>
          <p>{{tokenErrorMessage}}</p>
          <table v-show="azureResponseErrorDescription" class="error-details">
            <tr><th>{{ $t('message.error.code') }}</th><td>{{azureResponseErrorDescription.code}}</td></tr>
            <tr><th>{{ $t('message.error.description') }}</th><td>{{azureResponseErrorDescription.description}}</td></tr>
            <tr><th>{{ $t('message.error.event') }}</th><td>{{azureResponseErrorDescription.id}}</td></tr>
            <tr><th>{{ $t('message.error.timestamp') }}</th><td>{{azureResponseErrorDescription.timestamp}}</td></tr>
          </table>
          <p>
            <input type="submit" class="login-button button" :value="$t('message.connectAzureAuthentication.return')" @click="returnToLogin" />
          </p>
        </div>
      </div>
    </div>
    <div v-show="loginState === 'userblocked'">
      <div class="login-title">
        {{ $t('message.connectAzureAuthentication.userAccountBlocked.title') }}
      </div>
      <div class="login-selection-panel">
        <div class="login-instruction">
          <p class="error-icon"></p>
          <p>{{$t('message.connectAzureAuthentication.userAccountBlocked.message')}}</p>
          <p>
            <input type="submit" class="login-button button" :value="$t('message.connectAzureAuthentication.return')" @click="returnToLogin" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/configurations/config'
import authenticationProviders from '@/utils/authenticationProvider'
import cookie from '@/utils/cacheProviders/cookieCacheProvider'

import DxSelectBox from 'devextreme-vue/select-box'
import B2CLoadingSpinner from './B2CLoadingSpinner.vue'
import azureStateUtils from '@/utils/azureStateUtils'

export default {
  name: 'ConnectAzureAuthentication',
  data() {
    return {
      root: config.root,
      licenses: [],
      loadingLicenses: true,
      selectedLicense: null,
      selectedLanguage: null,
      tokenData: null,
      licenseIsFull: false,
      cuUsers: [],
      username: '',
      password: '',
      loginState: 'notoken',
      loginError: false,
      inviteError: false,
      tokenErrorMessage: null
    }
  },
  components: {
    B2CLoadingSpinner,
    DxSelectBox
  },
  async created() {
    this.isNotConnected = true
    this.loginState = 'notoken'

    // http://localhost:8080/Web/connect-azure?testdocs=true
    if (this.$route.query.testdocs) { this.loginState = 'displayazuredocumentation' }
    // http://localhost:8080/Web/connect-azure?testclassic=true
    if (this.$route.query.testclassic) { this.loginState = 'classiclogin' }
    // http://localhost:8080/Web/connect-azure?testcufailed=true
    if (this.$route.query.testcufailed) { this.loginState = 'cufailed' }
    // http://localhost:8080/Web/connect-azure?testselectlicense=true&id_token=<valid token>
    if (this.$route.query.testselectlicense) { this.loginState = 'selectlicense' }
    // http://localhost:8080/Web/connect-azure?testtokenfailed=true
    if (this.$route.query.testtokenfailed) { this.loginState = 'tokenfailed' }
    // http://localhost:8080/Web/connect-azure?testuserblocked=true
    if (this.$route.query.testuserblocked) { this.loginState = 'userblocked' }

    if (this.azureResponseError) {
      this.loginState = 'tokenfailed'
    } else {
      this.authenticationProvider = authenticationProviders[config.platform].default

      let inviteTokenHandled = true
      let inviteTokenHandledDuringCurrentRequest = false
      if (this.queryToken && this.inviteToken) {
        inviteTokenHandled = await this.handleInviteToken()
        if (inviteTokenHandled) {
          inviteTokenHandledDuringCurrentRequest = true
        }
      }

      if (this.queryToken) {
        cookie.set('idToken', this.queryToken)
        if (inviteTokenHandled) {
          await this.handleQueryToken(inviteTokenHandledDuringCurrentRequest)
        }
      }
    }
  },
  computed: {
    forgotClassicPasswordUrl() {
      return config.sso.ssoServer + '/password/new?return_uri=' + encodeURIComponent(window.location.href)
    },
    queryToken() {
      return this.$route.query.id_token
    },
    azureResponseError() {
      return this.$route.query.error
    },
    azureResponseErrorDescription() {
      if (this.$route.query.error_description) {
        let error = {
          code: this.$t('message.unknown'),
          description: this.$t('message.unknown'),
          id: this.$t('message.unknown'),
          timestamp: this.$t('message.unknown')
        }

        let parts = this.$route.query.error_description.split('\r\n')
        if (parts.length > 0) {
          let codeAndDescription = parts[0].split(':')
          if (codeAndDescription.length > 0) {
            error.code = codeAndDescription[0]
          }
          if (codeAndDescription.length > 1) {
            error.description = codeAndDescription[1]
          }
        }

        if (parts.length > 1) {
          let labelAndId = parts[1].split(':')
          if (labelAndId.length > 1) {
            error.id = labelAndId[1]
          }
        }

        if (parts.length > 2) {
          let labelAndTimestamp = parts[2].split(':')
          if (labelAndTimestamp.length > 0) {
            error.timestamp = parts[2].replace(labelAndTimestamp[0] + ':', '')
          }
        }

        return error
      }

      return false
    },
    inviteToken() {
      const state = azureStateUtils.getQueryParameterAzureState(this.$route)
      return state && state.invite_token ? state.invite_token : false
    },
    tableHeaders() {
      return [this.$t('columnTitles.UserName'), this.$t('columnTitles.LastActive')]
    }
  },
  methods: {
    async handleInviteToken() {
      try {
        const connectUserObject = {
          idToken: this.queryToken,
          inviteToken: this.inviteToken
        }

        await this.$store.dispatch('connectUserToAzureByInviteToken', connectUserObject)
        return true
      } catch (error) {
        if (error && error.statusText) {
          this.inviteError = this.getFriendlyErrorMessage(error.statusText)
        } else {
          this.inviteError = this.getFriendlyErrorMessage()
        }

        return false
      }
    },
    getFriendlyErrorMessage(errorMessage) {
      const defaultMessage = this.$t('message.connectAzureAuthentication.invalid-invitetoken')
      if (!errorMessage) {
        return defaultMessage
      }

      switch (errorMessage.toLowerCase()) {
        case 'the username is already used':
          return this.$t('message.connectAzureAuthentication.username-not-unique')

        default:
          return defaultMessage
      }
    },
    async handleQueryToken(inviteTokenHandledDuringCurrentRequest) {
      const userInfoResponse = await this.$store.dispatch('getUserInfoByAzureJwt', { idToken: this.queryToken })
      const userInfo = JSON.parse(userInfoResponse.body)
      if (userInfo.IsConnectedToAzure) {
        if (inviteTokenHandledDuringCurrentRequest) {
          this.loginState = 'displayazuredocumentation'
        } else {
          await this.loadLicenses()
        }
      } else if (userInfo.UserIsBlocked) {
        this.loginState = 'userblocked'
      } else {
        this.loginState = 'classiclogin'
      }
    },
    async onClassicLogin() {
      try {
        const connectUserObject = {
          idToken: this.queryToken,
          username: this.username,
          password: this.password
        }

        await this.$store.dispatch('connectUserToAzure', connectUserObject)
        this.loginState = 'displayazuredocumentation'
      } catch (error) {
        this.loginError = true
      }
    },
    async loadLicenses() {
      this.loadingLicenses = true
      this.loginState = 'nolicense'

      const licenseResponse = await this.$store.dispatch('getLicensesByAzureJwt', { idToken: this.queryToken })
      this.licenses = JSON.parse(licenseResponse.body).Licenses
      if (!this.licenses || this.licenses.length === 0) {
        return
      }

      for (let license of this.licenses) {
        license.displayValue = `${license.LicenseNumber} - ${license.CompanyName} - ${license.Description}`
      }

      if (this.licenses.length > 0) this.selectedLicense = this.licenses[0]
      if (this.licenses.length === 1) {
        await this.onSelectLicense()
      } else {
        this.loginState = 'selectlicense'
      }

      this.loadingLicenses = false
    },
    async onSelectLicense() {
      const cookieLang = cookie.get('language')

      const userTokenObject = {
        idToken: this.queryToken,
        licenseNumber: this.selectedLicense.LicenseNumber,
        language: cookieLang
      }

      var tokenDataResponse = null
      try {
        tokenDataResponse = await this.$store.dispatch('getClassicTokenByAzureJwt', userTokenObject)
      } catch (error) {
        this.tokenErrorMessage = error.statusText
        this.loginState = 'tokenfailed'
        return
      }

      if (!tokenDataResponse || !tokenDataResponse.data) {
        console.error('Could not get token data belonging to user', userTokenObject)
        this.tokenErrorMessage = this.$t('message.UnknownError')
        this.loginState = 'tokenfailed'
        return
      }

      this.tokenData = tokenDataResponse.data

      if (this.tokenData.LicenseIsFull) {
        // Note: 'classicToken' is null here!
        // You don't get a classic token when the license is full!
        this.licenseIsFull = true
        this.loginState = 'cufailed'

        const cuUsage = (
          await this.$store.dispatch('getLicenseUsageInformation', {
            idToken: this.queryToken,
            licenseNumber: this.selectedLicense.LicenseNumber
          })
        ).data

        this.cuUsers = cuUsage.OnlineUsers

        return
      }

      const classicToken = this.tokenData.ClassicToken

      cookie.set('licensenumber', classicToken.LicenseNumber)
      cookie.set('username', classicToken.UserName)
      cookie.set('access_token', classicToken.Token)

      this.$router.app.username = classicToken.UserName

      if (this.$route.query.testselectlicense) {
        return
      }

      const state = azureStateUtils.getQueryParameterAzureState(this.$route)

      // We could still be in an iframe here after being redirected by the Azure login.
      // window.top.location.href gets rid of the iframe.
      if (state && state.redirect_url) {
        window.top.location.href = decodeURIComponent(state.redirect_url)
      } else {
        window.top.location.href = config.apps.web
      }
    },
    returnToLogin() {
      window.location = config.sso.azureLogoutUrl + encodeURIComponent(config.apps.web)
    },
    async getUserInfo(azureJwt) {
      try {
        return await this.$store.dispatch('getUserInfo', { azureJwt })
      } catch (e) {
        this.handlerError(e)
      }
    },
    isUserConnectedToAzure() {
      return this.authenticationProvider.isUserConnectedToAzure()
    },
    onGenerateButtonClick() {
      const url = config.apps.web + 'generate-pincode?id_token=' + this.queryToken
      window.open(url, '_blank', 'noreferrer')

      // option within same window with redirect
      // const url = config.apps.web + 'generate-pincode?id_token=' + this.queryToken() + '&from=' + location.href
      // location.href = url
    },
    async onContinueButtonClick() {
      await this.loadLicenses()
    },
    onFocusSelectedLicense(e) {
      // Select the text in the search box, so the user can start typing immediately to search for a license
      e.element.querySelector('input.dx-texteditor-input').select()
    }
  }
}
</script>

<style lang="less">
// Unscoped to hide Freshdesk help button.
// Faux scoping everything else inside #azure-login.
#freshworks-container {
  display: none;
}

#azure-login {
  font-size: 16px;
  font-family: 'Roboto Flex', 'PT Sans', sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // Below two lines can make the background a little darker, if needed.
  // background-color: rgba(0,0,0,0.25);
  // background-blend-mode: multiply;
  background-image: url(/Web/assets/img/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center -400px;

  & .login-title {
    color: white;
    text-align: center;
    text-shadow: 0 2px 2px #000000aa;
    font-size: 24px;
    font-weight: 500;
    margin: 2em 0 1em;
  }

  & .login-invalid {
    color: red;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin: 2em 0 1em;
  }

  & .invite-invalid {
    color: red;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1em;
  }

  & .login-selection-panel {
    margin: auto;
    margin-top: 2em;
    border-radius: 0.5em;
    padding: 2em 3em;
    background-color: white;
    max-width: 25em;
  }

  & .login-documentation-panel {
    margin-top: 2em;
    border-radius: 0.5em;
    padding: 2em 3em;
    background-color: white;
    max-width: 50em;
  }

  & .login-instruction {
    padding-top: 1em;
    font-weight: bold;
    text-align: center;
  }

  & .login-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
  }

  & label {
    width: 100%;
    font-weight: 400;
    text-align: start;
    padding-left: 1em;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  & .login-select {
    width: 100%;
    height: 50px;
    margin: 0.5em 1em 1em;
    border: 1px solid #dbd9d7;
    border-radius: 4px;
    background-color: #f5f2f0;
  }

  & .button {
    padding: 20px;
    margin: 1em 0;
    border: 0;
    background-color: #89b368;
    color: white;
    border-radius: 12px;
    display: block;
    box-shadow: 0 2px 0 #658b49;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: 16px;

    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      outline:none;
      color:white;
      background-image: linear-gradient(to bottom, #658B49, #658B49);
    }
  }

  & button[type="submit"], .verifyCode{
    background-color:#89B368;
    color:white;
    text-decoration: none;
    background-image: linear-gradient(to bottom, #89B368, #658B49);
    z-index:0;
    border:0;
  }

  & .generate-button, & .continue-button {
    display: inline-block;
    margin-right: 20px;
  }

  & .login-button {
    width: 100%;

    &:active:hover {
      box-shadow: none;
      transform: translateY(2px);
    }

    &:disabled {
      color: grey;
      opacity: 0.6;
    }
  }

  & .error-icon::before {
    color: red;
    font-size: 400%;
    font-weight: 200;
    content: "\26A0";
  }

  & table {
    width: 100%;

    & thead tr {
      border-bottom: 1px solid grey;
    }

    & tbody tr td {
      padding-top: 4px;
      padding-left: 4px;
      padding-right: 4px;
      padding-bottom: 4px;
    }
  }

  & .error-details td,
  & .error-details th {
    padding-bottom: 5px;
  }

  & .error-details td {
    font-weight: normal;
  }

  & .error-details th {
    padding-right: 10px;
    vertical-align: top;
  }

  & .error-details {
    text-align: left;
    margin: 25px 0 25px 0;
    font-size: 15px;
  }
}

</style>
